@font-face {
font-family: '__bookAntiqua_ae5932';
src: url(/_next/static/media/87883047c4658c4b-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__bookAntiqua_Fallback_ae5932';src: local("Arial");ascent-override: 91.40%;descent-override: 27.94%;line-gap-override: 0.00%;size-adjust: 101.03%
}.__className_ae5932 {font-family: '__bookAntiqua_ae5932', '__bookAntiqua_Fallback_ae5932'
}.__variable_ae5932 {--font-book-antiqua: '__bookAntiqua_ae5932', '__bookAntiqua_Fallback_ae5932'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Sofia_Sans_fcf81f';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/3e588866f0fbd072-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Sofia_Sans_fcf81f';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/bce972c3f76a73e7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Sofia_Sans_fcf81f';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/6ca89e93dedab0a9-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Sofia_Sans_fcf81f';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/1d312b101034d17b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sofia_Sans_fcf81f';
  font-style: normal;
  font-weight: 1 1000;
  font-display: swap;
  src: url(/_next/static/media/8e047f7724433992-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sofia_Sans_Fallback_fcf81f';src: local("Arial");ascent-override: 92.82%;descent-override: 30.94%;line-gap-override: 0.00%;size-adjust: 96.96%
}.__className_fcf81f {font-family: '__Sofia_Sans_fcf81f', '__Sofia_Sans_Fallback_fcf81f';font-style: normal
}.__variable_fcf81f {--font-sofia-sans: '__Sofia_Sans_fcf81f', '__Sofia_Sans_Fallback_fcf81f'
}

/* latin-ext */
@font-face {
  font-family: '__Outfit_ad50fd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4f2204fa15b9b11a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Outfit_ad50fd';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/07a54048a9278940-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Outfit_Fallback_ad50fd';src: local("Arial");ascent-override: 100.55%;descent-override: 26.14%;line-gap-override: 0.00%;size-adjust: 99.45%
}.__className_ad50fd {font-family: '__Outfit_ad50fd', '__Outfit_Fallback_ad50fd';font-style: normal
}.__variable_ad50fd {--font-outfit: '__Outfit_ad50fd', '__Outfit_Fallback_ad50fd'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Caveat_3f0be0';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/e7b354626d982aa8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Caveat_3f0be0';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/147bfce26dd9c7af-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Caveat_3f0be0';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/c59e126942bb935a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Caveat_3f0be0';
  font-style: normal;
  font-weight: 400 700;
  font-display: swap;
  src: url(/_next/static/media/05f970ec0bd776d8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Caveat_Fallback_3f0be0';src: local("Arial");ascent-override: 125.37%;descent-override: 39.18%;line-gap-override: 0.00%;size-adjust: 76.57%
}.__className_3f0be0 {font-family: '__Caveat_3f0be0', '__Caveat_Fallback_3f0be0';font-style: normal
}.__variable_3f0be0 {--font-caveat: '__Caveat_3f0be0', '__Caveat_Fallback_3f0be0'
}

